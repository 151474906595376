
import { getCCData } from "@/main";
import { Builder } from "@/ts/api";
import { defineComponent, PropType, ref } from "vue";

export interface TokenizerResponse {
  message: string;
  expiry: string;
  token?: string;
  errorCode?: number;
  errorMessage?: string;
  entry?: string;
}
export default defineComponent({
  props: {
    builder: Object as PropType<Builder>,
  },
  emits: {
    getToken(payload: TokenizerResponse) {
      console.log(payload);
      return payload;
    },
  },
  data() {
    return {
      errorMessage: "",
      saveCard: true,
      useSavedCard: true,
      loadingSavedData: true,
      savedData: undefined as
        | undefined
        | {
            expiry: string;
            token: string;
          },
    };
  },
  mounted() {
    const _emit = (token: TokenizerResponse) => this.$emit("getToken", token);
    const setError = (message: string) => (this.errorMessage = message);
    window.addEventListener(
      "message",
      function (event) {
        try {
          var _token = JSON.parse(event.data) as TokenizerResponse;
          setError(_token.errorMessage ?? "");
          if (_token) _emit(_token);
        } catch (e) {
          if (!(e instanceof SyntaxError)) throw e;
        }
      },
      false
    );

    //TODO get saved card data
    getCCData({ uid: this.builder?.builderID })
      .then(
        (resp: {
          data?: {
            token?: string;
            expiry?: string;
          };
        }) => {
          console.log(resp.data);
          if (
            (resp.data?.token ?? "").length > 4 &&
            (resp.data?.expiry ?? "").length == 4
          ) {
            this.savedData = {
              token: resp.data!.token!,
              expiry: resp.data!.expiry!,
            };
            this.$emit("getToken", {
              message: "success",
              token: resp.data!.token!,
              expiry: resp.data!.expiry!,
            });
          }
        }
      )
      .finally(() => {
        this.loadingSavedData = false;
      });
  },
  computed: {
    css() {
      let css = `
      body {
        color: white;
        font-family: -apple-system, system-ui, Helvetica, Arial, sans-serif;
        margin: 0;
      }
      label {
        font-size: 14px;
      }
      input, select {
        outline: none;
        color: white;
        background-color: transparent;
        font-size: 16px;
        padding: 8px 12px;
        margin: 8px 0;
        border: 1.2px solid rgba(255, 255, 255, 0.2);
      }
      select option {
        background-color: #1C1C1D;
      }
      `;
      return encodeURIComponent(css);
    },
  },
  watch: {
    useSavedCard(val: boolean) {
      if (
        val &&
        this.savedData?.expiry.length == 4 &&
        this.savedData?.token.length > 4
      )
        this.$emit("getToken", {
          message: "success",
          token: this.savedData!.token!,
          expiry: this.savedData!.expiry!,
        });
      else
        this.$emit("getToken", {
          message: "",
          expiry: "",
        });
    },
  },
});


import BillOfMaterials from "@/components/BillOfMaterials.vue";
import { appState, importSalesOrder } from "@/main";
import { Builder, saveAddresses } from "@/ts/api";
import { Address, currency, getItems, Item } from "@/ts/api/store";
import { defineComponent, Ref } from "@vue/runtime-core";
import { Progressbar, f7, f7ready } from "framework7-vue";
import { Router } from "framework7/types";
import { nanoid } from "nanoid";
import { PropType, ref } from "vue";
import CardPointe, { TokenizerResponse } from "./components/CardPointe.vue";
import { apps } from "firebase-functions/lib/apps";

interface Package {
  name: string;
  willCall: boolean;
  address: Address;
  shipDate: Date[];
  BOM: Item[];
}

export enum SalesOrderStatus {
  Estimate = 10,
  Issued = 20,
  Cancelled = 85,
  Historical = 95,
}
export enum SalesOrderItemType {
  Sale = 10,
  MiscSale = 11,
  DropShip = 12,
  CreditReturn = 20,
  MiscCredit = 21,
  DiscountPercentage = 30,
  DiscountAmount = 31,
  Subtotal = 40,
  AssocPrice = 50,
  Shipping = 60,
  Tax = 70,
  Kit = 80,
  None = 90,
  BOMConfigurationItem = 100,
}
export interface SalesOrder {
  builderID?: string;
  number: string;
  status: SalesOrderStatus;
  billingAddress: Address;
  shippingAddress: Address;
  carrierName: string;
  taxRateName: string;
  orderDateScheduled: string;
  isCcPaymentBranch: true;
  billOfMaterials: [
    {
      type: SalesOrderItemType;
      quantity: number;
      productDescription: string;
      productNumber: string;
      uom: "ea";
      notes: string;
    }
  ];
  PONum?: string;
  cardPointeData?: {
    /** Cardpointe account token (e.g. 9440670166031111) */
    account: string;
    /** MMDD (e.g. 0826) */
    expiry: string;
    /** USD (e.g. 12.38) */
    amount: number;
    /** Save this account on CardPointe for future use */
    saveAccount?: false;
    /** Postal code (e.g. 12345) */
    postal: string;
  };
}

const defaultAddress: Address = {
  name: "",
  street: "",
  city: "",
  state: "",
  zip: "",
};

export default defineComponent({
  props: {
    builder: Object as PropType<Builder>,
    PONum: String,
    items: Object as PropType<Item[]>,
    usePackages: Object as PropType<boolean>,
    callback: Object as PropType<() => Promise<void>>,
    f7route: Object as PropType<Router.Route>,
    f7router: Object as PropType<Router.Router>,
  },
  components: {
    BillOfMaterials,
    CardPointe,
  },
  setup(props) {
    const packages = ref([] as Package[]);

    let items = props.items ?? [];

    // Calculate the start ship date. Don't allow saturdays or sundays
    var defaultShipDate = new Date(Date.now() + 172800000);
    if (defaultShipDate.getDay() == 0) {
      // Add a day
      defaultShipDate = new Date(defaultShipDate.getTime() + 86400000);
    } else if (defaultShipDate.getDay() == 6) {
      // Add 2 days
      defaultShipDate = new Date(defaultShipDate.getTime() + (86400000 * 2));
    }

    if (props.usePackages) {
      // separate the items into groups via their packageID
      let itemMap = new Map<string, Item[]>();
      for (let i = 0; i < items.length; i++) {
        const _item = items[i];
        if (!itemMap.has(_item.packageID)) {
          itemMap.set(_item.packageID, []);
        }
        itemMap.get(_item.packageID)?.push(_item);
      }

      // sort by keys
      itemMap = new Map([...itemMap].sort());



      // generate the package array from the itemMap
      itemMap.forEach((packageItems, i) => {
        packages.value.push({
          name: packageItems[0].packageName,
          willCall: false,
          address:
            appState.builderData?.savedAddresses["package-" + i] ??
            defaultAddress,
          shipDate: [defaultShipDate],
          BOM: packageItems,
        });
      });
    } else {
      packages.value.push({
        name: "Order",
        willCall: false,
        address:
          appState.builderData?.savedAddresses["package-0"] ??
          defaultAddress,
        shipDate: [defaultShipDate],
        BOM: items,
      });
    }

    let totalPrice = ref(0);
    f7ready(() => {
      if (appState.admin) {
        //get builder pricing
        let _preloader = f7.dialog.preloader("Loading builder pricing...");
        getItems(items.map(i => i.sku), props.builder?.customerID).then((newValues) => {

          newValues.forEach((newItem) => {
            const oldItem = items.find((i) => i.sku === newItem.sku);
            if (oldItem) {
              oldItem.updateItem(newItem);
            }
          });
          packages.value.forEach((p) =>
            p.BOM.forEach((i) => (totalPrice.value += i.price * i.count)));
        }).finally(() => _preloader.close());
      } else {
        packages.value.forEach((p) =>
          p.BOM.forEach((i) => (totalPrice.value += i.price * i.count))
        );
      }
    });


    return {
      packages: packages,
      totalPrice: totalPrice,
      billToAddress: ref(
        appState.builderData?.savedAddresses["bill-to"] ?? defaultAddress
      ),
      placingOrder: ref(false),
      paymentMethod: ref("invoice"),
      CCTokenData: ref() as Ref<TokenizerResponse | undefined>,
    };
  },
  methods: {
    validateAddress(a: Address): boolean {
      return Object.values(a).every((v) => v && v.length > 0);
    },
    // Returns true if the given date is invalid for shipping
    notValidShipDate(date: Date): boolean {
      if (date.getDay() == 0 || date.getDay() == 6) {
        return true;
      } else {
        return false;
      }
    },
    async purchase() {
      if (!this.validateAddress(this.billToAddress)) {
        f7.dialog.alert("You're missing billing address info.");
      }

      this.placingOrder = true;
      try {
        // Get builder number
        let builderNumber = "unknown";
        if (this.builder?.customer.number)
          builderNumber = this.builder.customer.number;
        else throw "number not found";

        // start compiling individual sales orders from packages
        let a = {} as { [key: string]: Address };
        let orders: SalesOrder[] = [];
        this.packages.forEach((p, i) => {
          // validate address & shipping date
          if (p.shipDate)
            if (!p.willCall && !this.validateAddress(p.address)) {
              f7.dialog.alert(
                `You're missing shipping info for the ${p.name} Package`
              );
              throw "missing details";
            }

          // add country prop to the address
          let ba: any = this.billToAddress;
          let sa: any = p.address;
          ba.country = sa.country = "United States";

          let order = {
            number: builderNumber + "-" + nanoid(8),
            // specify custom builder ID if user is an admin
            builderID: this.builder?.builderID,
            // usually a job ID
            PONum: this.PONum,
            status: SalesOrderStatus.Issued,
            billingAddress: ba,
            shippingAddress: !p.willCall ? sa : {},
            carrierName: p.willCall ? "Will Call" : "UPS",
            taxRateName: "None",
            orderDateScheduled: `${p.shipDate[0].getMonth() + 1
              }/${p.shipDate[0].getDate()}/${p.shipDate[0].getFullYear()}`,
            billOfMaterials: p.BOM.map((item) => {
              return {
                type: SalesOrderItemType.Sale,
                quantity: item.count,
                productNumber: item.number,
                productDescription: item.name,
                uom: "ea",
                notes: item.notes,
              };
            }),
          } as SalesOrder;

          // add credit card token
          if (this.paymentMethod === "credit" && this.CCTokenData?.token)
            order.cardPointeData = {
              account: this.CCTokenData.token,
              expiry: this.CCTokenData.expiry,
              amount: this.totalPrice,
              postal: this.billToAddress.zip,
            };

          orders.push(order);

          // save package addresses
          a[`package-${i + 1}`] = p.address;
        });
        a["bill-to"] = this.billToAddress;
        saveAddresses(a);
        for await (const order of orders) {
          await importSalesOrder(order).catch((e) => {
            console.error("SO FAILED", e);
            f7.dialog.alert(
              e.message ?? "Failed to submit order. Please contact support.",
              "An error occurred"
            );
            throw e;
          });
        }
        if (this.callback) await this.callback();
        this.f7router?.navigate("/jobs");
        f7.dialog.alert(
          "Check your email for an order confirmation.",
          "Your order has been placed."
        );
      } catch (e) {
        console.error("failed to place order:", e);
      } finally {
        this.placingOrder = false;
      }
    },
    updateToken(t: TokenizerResponse) {
      this.CCTokenData = t;
    }
  },
  computed: {
    readablePrice(): string {
      return currency.format(this.totalPrice);
    },
    validatePurchase(): boolean {
      // no payment method yet
      if (this.paymentMethod == "") return false;

      // credit method but no credentials entered
      if (
        this.paymentMethod === "credit" &&
        (this.CCTokenData?.token ?? "").length < 1
      )
        return false;

      return true;
    },
    appState: () => appState,
  },
});
